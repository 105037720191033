import {OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from "@angular/material";
import {FindResult} from "app/view/custom/find/find.component";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {GPS} from "app/lib/api";
import {AddressProvider} from "app/view/custom/provider_address/address_provider";
import {AddressModelProvider} from "app/view/custom/provider_address/address_model_provider";
import {Angular5Csv, Options} from "angular5-csv/dist/Angular5-csv";
import {GpsApi} from "app/data/source/cloud/gps-api";

export class ReportBase implements OnInit, OnDestroy {
  result: FindResult;
  dataSource = new MatTableDataSource<ReportGPSClass[]>();
  displayedColumns: string[];
  hasMore = false;
  cursor: string;
  loading: boolean;
  empty = false;
  searched = false;
  dateStart: Date;
  dateEnd: Date;
  map = new Map<string, AddressModelProvider>();
  data: Array<ReportGPSClass>;
  options: Options = {} as Options;

  constructor(
    protected gpsApi: GpsApi,
    public providerRequest: AddressProvider,
    protected dialog: MatDialog
  ) {
  }

  getAddress(latitude: number, longitude: number): string {
    return this.providerRequest.getAddress(latitude, longitude);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.providerRequest.clearPendingAddresses();
  }

  public received(value, nextPageToken) {
    this.loading = false;

    this.hasMore = this.cursor != nextPageToken;

    this.cursor = nextPageToken;

    if (value) {
      this.dataSource.data = this.dataSource.data.concat(value);
    } else {
      this.hasMore = false
    }

    this.empty = this.dataSource.data.length == 0;
  }

  public error(reason) {
    new ErrorManager(reason, this.dialog);
    this.reset();
  }

  public reset() {
    this.loading = false;
    this.searched = false;
    this.empty = false;
    this.hasMore = false;
    this.cursor = null;
    this.dataSource.data = [];
    this.data = [];
    this.providerRequest.clearPendingAddresses();
  }

  saveCSV() {
    let gpsClasses = this.data.map(function (value: GPS, index) {
      //   "Ignição",
      //   "Altitude",
      //   "Cursor",
      //   "Horario GPS (GMT-0)",
      //   "Sinal GSM (%)",
      //   "IMEI",
      //   "Lat",
      //   "Lng",
      //   "Bloqueio",
      //   "Bateria (%)",
      //   "Modelo",
      //   "Recebido (GMT-0)",
      //   "Velocidade",

      return {
        acc: value.acc == true ? "Ligado" : "Desligado",
        altitude: value.altitude,
        course: value.course,
        gpsTime: value.gpsTimeNormalized,
        gsm: value.gsm,
        imei: value.imei,
        latitude: value.latitude,
        longitude: value.longitude,
        oil: value.oil == true ? "Bloqueado" : "Desbloqueado",
        power: value.power,
        protocol: value.protocol,
        received: value.receivedDate,
        speed: value.speed + "km/h",
      };
    });

    new Angular5Csv(gpsClasses, "Relatório", this.options)
  }

  protected onResult(findResult: FindResult) {
    if (findResult == null) {
      return;
    }

    this.reset();

    this.searched = true;

    this.result = findResult;

    this.search();
  }

  protected search() {

  }
}
