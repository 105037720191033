import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Utils} from "app/infrastructure/utils/Utils";
import {GoogleAddressProvider} from "app/view/custom/provider_address/google-provides-address/google-address-provider";
import {Device, GPS} from "app/lib/api";
import {DeviceGPS} from "app/domain/model/gps-device";

@Component({
  selector: 'app-dialog-details',
  templateUrl: './dialog-details.component.html',
  styleUrls: ['./dialog-details.component.scss']
})
export class DialogDetailsComponent implements OnInit {
  device: Device;
  gps: GPS;
  private address: String = "Buscando endereço...";

  constructor(public dialogRef: MatDialogRef<DialogDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DeviceGPS,
              private utils: Utils,
              private addressProvider: GoogleAddressProvider
  ) {
    this.gps = data.gps;
    this.device = data.device;
  }

  ngOnInit(): void {
  }

  getAddress(latitude: number, longitude: number): string {
    return this.addressProvider.getAddress(latitude, longitude);
  }

  latitudeDMS(gps: GPS): string {
    return Utils.convertLatDMS(gps.latitude);
  }

  longitudeDMS(gps: GPS): string {
    return Utils.convertLngDMS(gps.longitude);
  }

  getBatteryIcon() {
    return this.utils.getBatteryIcon(this.gps);
  }

  getSpeedColor() {
    return this.utils.getSpeedColor(this.gps);
  }

  getBatteryColor() {
    return this.utils.getBatteryColor(this.gps)
  }

  getAccColor() {
    return this.utils.getAccColor(this.gps)
  }

  getLockIcon() {
    return this.utils.getLockIcon(this.gps)
  }

  getLockColor() {
    return this.utils.getLockColor(this.gps)
  }

  getGCMColor() {
    return this.utils.getGCMColor(this.gps)
  }

  getGSMIcon() {
    return Utils.getGSMIcon(this.gps)
  }

  close() {
    this.dialogRef.close();
  }
}
